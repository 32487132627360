import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.css';
import 'react-widgets/dist/css/react-widgets.css';

import './App.css';

import { Colors } from './components/Colors';
import { JSONPretty } from './components/JSONPretty';
import { LaTeXURLSanitizer } from './components/LaTeXURLSanitizer';
import { MarkdownImageLinkConverter } from './components/MarkdownImageLinkConverter';
import { OPCUANodeIdConverter } from './components/OPCUANodeIdConverter';
import { QRCodeGenerator } from './components/QRCodeGenerator';
import { SQLFormatter } from './components/SQLFormatter';
import { URLEnDeCoder } from './components/URLEnDeCoder';
import { XMLPretty } from './components/XMLPretty';
import { Box } from './common/Box';
import { Helmet } from 'react-helmet';
import { ErrorBoundary } from './common/ErrorBoundary';

function App() {
  return (
    <div className="App">
      <Helmet titleTemplate={`%s - Webtools`} defaultTitle={`Webtools`}>
        <meta
          name="description"
          content={`A collection of tools for the web and industry 4.0`}
        />
      </Helmet>
      <Container fluid>
        <Row>
          <Col md={4}>
            <ErrorBoundary>
              <Box>
                <QRCodeGenerator />
              </Box>
            </ErrorBoundary>
          </Col>
          <Col md={2}>
            <ErrorBoundary>
              <Box>
                <OPCUANodeIdConverter />
              </Box>
            </ErrorBoundary>
          </Col>
          <Col md={2}>
            <ErrorBoundary>
              <Box>
                <MarkdownImageLinkConverter />
              </Box>
            </ErrorBoundary>
          </Col>
          <Col md={2}>
            <ErrorBoundary>
              <Box>
                <LaTeXURLSanitizer />
              </Box>
            </ErrorBoundary>
          </Col>
          <Col md={2}>
            <ErrorBoundary>
              <Box>
                <URLEnDeCoder />
              </Box>
            </ErrorBoundary>
          </Col>
          <Col md={4}>
            <ErrorBoundary>
              <Box>
                <Colors />
              </Box>
            </ErrorBoundary>
          </Col>
          <Col md={6}>
            <ErrorBoundary>
              <Box>
                <JSONPretty />
              </Box>
            </ErrorBoundary>
          </Col>
          <Col md={6}>
            <ErrorBoundary>
              <Box>
                <SQLFormatter />
              </Box>
            </ErrorBoundary>
          </Col>
          <Col md={6}>
            <ErrorBoundary>
              <Box>
                <XMLPretty />
              </Box>
            </ErrorBoundary>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
